<template>
    <b-container fluid class="b-container-no-padding">
        <edit-posted-layer
            v-if="editLayerType.toLowerCase() === 'posted'"
            @close="clearEditLayer"
            :datasetId="editLayerId"
            :datasetIdx="editLayerIndex"
        />
        <edit-contour-layer
            v-else-if="editLayerType.toLowerCase() === 'contour'"
            @close="clearEditLayer"
            :jobID="editLayerId"
            :datasetIdx="editLayerIndex"
        />
        <div v-show="editLayerType === ''">
            <CommonTabsComponent
                :tabList="tabList"
                :activeTab="goToTab"
                @tabChanged="tabChanged"
            ></CommonTabsComponent>
            <component v-bind:is="componentName" @edit="editPostedLayer" />
        </div>
    </b-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'LayersWrapper',
    components: {
        CommonTabsComponent: () =>
            import('../CommonComponents/CommonTabsComponent.vue'),
        ProjectLayers: () => import('../EmergencyManagement/ProjectLayers.vue'),
        PostedLayers: () => import('../EmergencyManagement/PostedLayers.vue'),
        EditPostedLayer: () =>
            import('../CommonComponents/DataPostingTools/EditPostedLayer.vue'),
        EditContourLayer: () =>
            import('../CommonComponents/DataPostingTools/ContouringEdit.vue'),
    },
    data() {
        return {
            selectedTabName: 'Project layers',
            tabList: [{ name: 'Project layers', component: 'project-layers' }],
            editLayerId: -1,
            editLayerIndex: -1,
            editLayerType: '',
            goToTab: 'Project layers',
        };
    },
    methods: {
        tabChanged(newTab, prevTab) {
            this.selectedTabName = newTab.name;
        },
        populateTabList() {
            if (
                this.$store.state.projects.selectedProjects.some((selected) =>
                    selected.ProjectTypeName.includes('Emergency')
                )
            ) {
                this.tabList = [
                    { name: 'Project layers', component: 'project-layers' },
                ];
            } else {
                this.tabList = [
                    { name: 'Project layers', component: 'project-layers' },
                    { name: 'Posted layers', component: 'posted-layers' },
                ];
            }
            return this.tabList;
        },
        editPostedLayer(editPayload) {
            this.editLayerId = editPayload.layerId;
            this.editLayerIndex = editPayload.layerIndex;
            this.editLayerType = editPayload.layerType;
            this.$store.commit('oneMap/setIsEditingPostedLayer', true);
        },
        clearEditLayer() {
            this.editLayerId = -1;
            this.editLayerIndex = -1;
            this.editLayerType = '';
            this.goToTab = 'Posted layers';
            this.$store.commit('oneMap/setIsEditingPostedLayer', false);
        },
    },
    mounted() {
        this.populateTabList();
    },
    computed: {
        ...mapGetters('projects', {
            selectedProjects: 'selectedProjects',
        }),
        componentName() {
            return this.tabList.find((tab) => tab.name === this.selectedTabName)
                .component;
        },
    },

    watch: {
        selectedProjects(newselectedProjects) {
            if (newselectedProjects.length !== 0) {
                this.populateTabList();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.b-container-no-padding {
    padding: 0px;
}
</style>
